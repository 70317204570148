/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ModalHojaInfo from '../ModalHojaInfo/ModalHojaInfo';

import style from './styles';

import {
  searchByCedula,
  validateRenuncia,
  searchIsOnPuestosMunicipales,
} from '../../Core/Api/padronApi';
import {
  validateCandidato,
  validateFiscal,
} from '../../Core/Api/papeletaApi';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const ListSearch = props => {
  const {
    classes,
    title,
    formik,
    initListData,
    sexOrderM,
    sexOrderF,
    fieldToShow,
    // eslint-disable-next-line no-unused-vars
    inPlace,
    setListFunc,
    findInOtherList,
    otherList,
    validateBy,
    requireSexOrder,
    sexFieldAs,
    onlyJuventud,
    hideSexColumn,
    needHojaInfo,
    isFiscal,
  } = props;

  // Opciones Seleccionadas
  const [listData, setListData] = useState(initListData);
  const [currentPerson, setCurrentPerson] = useState({});
  const [
    currentSexChanged,
    setCurrentSexChanged,
  ] = useState(null);
  const [inputNameField, setInputNameField] = useState('');
  const [lugarField, setLugarField] = useState(null);

  // Dialog Data
  const [msjDialog, setMsjDialog] = useState('');
  const [open, setOpenDialog] = React.useState(false);
  const [openHojaInfo, setOpenHojaInfo] = React.useState(
    false,
  );
  const [
    msjOnRegimenMunicipal,
    setMsjOnRegimenMunicipal,
  ] = useState(false);
  const handleCloseHojaInfo = isData => {
    setOpenHojaInfo(false);
    if (!isData) {
      formik.setFieldValue(inputNameField, '');
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
    if (msjOnRegimenMunicipal) {
      setOpenHojaInfo(true);
      setMsjOnRegimenMunicipal(false);
    }
  };

  const setAllList = list => {
    setListData(list);
    setListFunc(list);
  };

  const addPersonToRow = selectedSex => {
    // Se obtiene el campo del delegado a editar
    const delegadoVal = Lodash.find(listData, {
      id: lugarField,
    });

    // Sexo de la persona
    let sexValNew = 'M';
    if (parseInt(currentSexChanged) !== 1) {
      sexValNew = 'F';
    }

    if (delegadoVal.id !== 1) {
      if (sexValNew !== delegadoVal.sexo) {
        const sexMessage =
          'El candidato a ingresar debe de respetar la alternabilidad.';
        setMsjDialog(sexMessage);
        setOpenDialog(true);
        return;
      }
    }

    // Se realiza una copia de la persona para modificar el sexo
    const clonedPerson = {
      ...currentPerson,
      sexo: selectedSex,
    };
    const personName = `${clonedPerson.des_nombre} ${clonedPerson.des_apell1} ${clonedPerson.des_apell2}`;
    const personCedula = clonedPerson.num_cedula;

    // Sexo de la persona
    let sexVal = 'M';
    if (clonedPerson.sexo !== 1) {
      sexVal = 'F';
    }

    let listToDoSearch = listData;

    if (lugarField === 1) {
      let sexOrder = sexOrderM;
      if (clonedPerson.sexo !== 1) {
        sexOrder = sexOrderF;
      }

      const newListData = Lodash.map(
        listData,
        (d, index) => {
          const newData = {
            id: d.id,
            lugar: d.lugar,
            numPuesto: d.numPuesto,
            fieldName: d.fieldName,
            cedula: index === 0 ? d.cedula : '',
            nombre: index === 0 ? d.nombre : '',
            sexo: requireSexOrder
              ? sexOrder[index].sexo
              : sexFieldAs,
            edad: d.edad,
            enabled: index === 0,
            filled: d.filled,
          };

          return newData;
        },
      );

      // Se limpian todos los campos
      for (let i = 0; i < newListData.length; i++) {
        if (i !== 0) {
          formik.setFieldValue(
            newListData[i].fieldName,
            '',
          );
        }
      }

      listToDoSearch = newListData;
    }

    // Se obtiene el campo del delegado a editar
    const delegado = Lodash.find(listToDoSearch, {
      id: lugarField,
    });

    delegado.sexo = sexVal;
    delegado.cedula = personCedula;
    delegado.nombre = personName;
    delegado.edad = clonedPerson.num_edad;
    delegado.enabled = true;
    delegado.filled = true;

    const newList = Lodash.union(
      [delegado],
      listToDoSearch,
    );

    const orderedList = Lodash.orderBy(
      newList,
      ['id'],
      ['asc'],
    );
    setAllList(orderedList);
  };

  const doSearchByCedula = async (
    numCedula,
    inputName,
    lugar,
  ) => {
    // Se busca por número de cédula
    searchByCedula(numCedula).then(async r => {
      const info = Lodash.get(r, ['data', 'response'], []);
      if (info !== false) {
        // Datos de la persona
        const person = Lodash.get(info, [0]);

        if (person.activo < 1) {
          setMsjDialog(
            'Esta persona NO se encuentra activa en el PADRÓN ELECTORAL. Favor ingresar otra persona',
          );
          setOpenDialog(true);
          formik.setFieldValue(inputName, '');
        } else {
          let isMjsRegimenActivate = false;
          if (needHojaInfo === true) {
            /*
             * NUEVA MODIFICACION: (para todas las inscripciones)
             * Se debe advertir al gestor que se esta inscribiendo a una persona que pertenece al régimen municipal.
             * Fecha: 2025-01-05
             */
            const isOnRegimenMunicipal = await searchIsOnPuestosMunicipales(
              numCedula,
            );
            if (isOnRegimenMunicipal.data.response >= 1) {
              setMsjDialog(
                `Esta persona está ocupando un puesto de elección popular municipal. Favor comuníquese al departamento de Finanzas al 2549-5512, para revisar el estado de sus aportes.`,
              );
              setOpenDialog(true);
              setMsjOnRegimenMunicipal(true);
              isMjsRegimenActivate = true;
              // formik.setFieldValue(inputName, '');
            }
          }
          // else {
          // Se valida que la persona sea valida para continuar con el proceso
          let validPerson = true;
          if (onlyJuventud) {
            if (person.num_edad >= 36) {
              validPerson = false;
            }
          }

          if (validPerson) {
            // Nombre de la persona
            const personName = `${person.des_nombre} ${person.des_apell1} ${person.des_apell2}`;
            const personCedula = person.num_cedula;
            // console.log('person', person);
            let personPlace = false;
            let _isPersonProvincia = true;
            let _isPersonCanton = true;
            let _isPersonDistrito = true;

            // VALIDATE DISTRITALES
            if (validateBy.length === 3) {
              if (
                parseInt(validateBy[0].value) !==
                parseInt(person.cod_prov)
              ) {
                _isPersonProvincia = false;
              }

              if (
                parseInt(validateBy[1].value) !==
                parseInt(person.cod_cant)
              ) {
                _isPersonCanton = false;
              }

              if (
                parseInt(validateBy[2].value) !==
                parseInt(person.cod_dist_adm)
              ) {
                _isPersonDistrito = false;
              }

              if (
                _isPersonProvincia &&
                _isPersonCanton &&
                _isPersonDistrito
              ) {
                personPlace = true;
              }
            }

            // VALIDATE CANTONALES
            if (validateBy.length === 2) {
              if (
                parseInt(validateBy[0].value) !==
                parseInt(person.cod_prov)
              ) {
                _isPersonProvincia = false;
              }

              if (
                parseInt(validateBy[1].value) !==
                parseInt(person.cod_cant)
              ) {
                _isPersonCanton = false;
              }

              if (_isPersonProvincia && _isPersonCanton) {
                personPlace = true;
              }
            }

            // VALIDATE PROVINCIALES & NONE
            if (validateBy.length === 1) {
              if (validateBy[0].dataVal === 'none') {
                personPlace = true;
              } else {
                if (
                  parseInt(validateBy[0].value) !==
                  parseInt(person.cod_prov)
                ) {
                  _isPersonProvincia = false;
                }

                if (_isPersonProvincia) {
                  personPlace = true;
                }
              }
            }

            // Sexo de la persona
            let sexVal = 'M';
            if (person.sexo !== 1) {
              sexVal = 'F';
            }

            let listToDoSearch = listData;
            if (personPlace) {
              // Se valida si requiere la HOJA DE INFORMACION
              if (needHojaInfo) {
                setCurrentSexChanged(person.sexo);
                if (lugar === 1) {
                  let sexOrder = sexOrderM;
                  if (person.sexo !== 1) {
                    sexOrder = sexOrderF;
                  }

                  const newListData = Lodash.map(
                    listData,
                    (d, index) => {
                      const newData = {
                        id: d.id,
                        lugar: d.lugar,
                        numPuesto: d.numPuesto,
                        fieldName: d.fieldName,
                        cedula: index === 0 ? d.cedula : '',
                        nombre: index === 0 ? d.nombre : '',
                        sexo: requireSexOrder
                          ? sexOrder[index].sexo
                          : sexFieldAs,
                        edad: d.edad,
                        enabled: index !== 0,
                        filled: d.filled,
                      };

                      return newData;
                    },
                  );

                  // Se limpian todos los campos
                  for (
                    let i = 0;
                    i < newListData.length;
                    i++
                  ) {
                    if (i !== 0) {
                      formik.setFieldValue(
                        newListData[i].fieldName,
                        '',
                      );
                    }
                  }

                  listToDoSearch = newListData;
                }

                // Se obtiene el campo del delegado a editar
                const delegado = Lodash.find(
                  listToDoSearch,
                  {
                    id: lugar,
                  },
                );

                if (
                  delegado.sexo === sexVal ||
                  person.sexo === null
                ) {
                  if (!isFiscal) {
                    // SE VALIDA EL CANDIDATO
                    validateCandidato(numCedula).then(
                      vResult => {
                        if (vResult.data > 0) {
                          setMsjDialog(
                            'La persona que intenta registrar ya se inscribió como fiscal en una papeleta distrital. No puede inscribirse en este puesto',
                          );
                          setOpenDialog(true);
                          document.getElementById(
                            `cp-${inputName}`,
                          ).style.display = 'none';
                          formik.setFieldValue(
                            inputName,
                            '',
                          );
                        } else {
                          setCurrentPerson(person);
                          if (!isMjsRegimenActivate) {
                            setOpenHojaInfo(true);
                          }
                        }
                      },
                    );
                  } else {
                    validateFiscal(numCedula).then(
                      vResult => {
                        if (vResult.data > 0) {
                          setMsjDialog(
                            'La persona que intenta registrar ya se inscribió como candidato(a) en otra papeleta. No puede inscribirse en este puesto',
                          );
                          setOpenDialog(true);
                          document.getElementById(
                            `cp-${inputName}`,
                          ).style.display = 'none';
                          formik.setFieldValue(
                            inputName,
                            '',
                          );
                        } else {
                          setCurrentPerson(person);
                          if (!isMjsRegimenActivate) {
                            setOpenHojaInfo(true);
                          }
                        }
                      },
                    );
                  }
                } else {
                  delegado.sexo = requireSexOrder
                    ? delegado.sexo
                    : sexFieldAs;
                  delegado.cedula = '';
                  delegado.nombre = '';
                  delegado.edad = '';
                  delegado.enabled = false;
                  delegado.filled = false;

                  const newList = Lodash.union(
                    [delegado],
                    listToDoSearch,
                  );

                  const orderedList = Lodash.orderBy(
                    newList,
                    ['id'],
                    ['asc'],
                  );

                  setAllList(orderedList);

                  let sexMessage =
                    'El candidato a ingresar debe de respetar la alternabilidad.';
                  if (!requireSexOrder) {
                    sexMessage =
                      'En esta papeleta solo se permite de ingresar mujeres';
                  }
                  setMsjDialog(sexMessage);
                  setOpenDialog(true);
                  // Se limpian el campo
                  formik.setFieldValue(inputName, '');
                }
              } else {
                if (lugar === 1) {
                  let sexOrder = sexOrderM;
                  if (person.sexo !== 1) {
                    sexOrder = sexOrderF;
                  }

                  const newListData = Lodash.map(
                    listData,
                    (d, index) => {
                      const newData = {
                        id: d.id,
                        lugar: d.lugar,
                        numPuesto: d.numPuesto,
                        fieldName: d.fieldName,
                        cedula: index === 0 ? d.cedula : '',
                        nombre: index === 0 ? d.nombre : '',
                        sexo: requireSexOrder
                          ? sexOrder[index].sexo
                          : sexFieldAs,
                        edad: d.edad,
                        enabled: index === 0,
                        filled: d.filled,
                      };

                      return newData;
                    },
                  );

                  // Se limpian todos los campos
                  for (
                    let i = 0;
                    i < newListData.length;
                    i++
                  ) {
                    if (i !== 0) {
                      formik.setFieldValue(
                        newListData[i].fieldName,
                        '',
                      );
                    }
                  }

                  listToDoSearch = newListData;
                }

                // Se obtiene el campo del delegado a editar
                const delegado = Lodash.find(
                  listToDoSearch,
                  {
                    id: lugar,
                  },
                );

                if (delegado.sexo === sexVal) {
                  if (!isFiscal) {
                    // SE VALIDA EL CANDIDATO
                    validateCandidato(numCedula).then(
                      vResult => {
                        if (vResult.data > 0) {
                          setMsjDialog(
                            'La persona que intenta registrar ya se inscribió como fiscal en una papeleta distrital. No puede inscribirse en este puesto',
                          );
                          setOpenDialog(true);
                          document.getElementById(
                            `cp-${inputName}`,
                          ).style.display = 'none';
                          formik.setFieldValue(
                            inputName,
                            '',
                          );
                        } else {
                          delegado.sexo = sexVal;
                          delegado.cedula = personCedula;
                          delegado.nombre = personName;
                          delegado.edad = person.num_edad;
                          delegado.enabled = true;
                          delegado.filled = true;

                          const newList = Lodash.union(
                            [delegado],
                            listToDoSearch,
                          );

                          const orderedList = Lodash.orderBy(
                            newList,
                            ['id'],
                            ['asc'],
                          );
                          setAllList(orderedList);
                        }
                      },
                    );
                  } else {
                    validateFiscal(numCedula).then(
                      vResult => {
                        if (vResult.data > 0) {
                          setMsjDialog(
                            'La persona que intenta registrar ya se inscribió como candidato(a) en otra papeleta. No puede inscribirse en este puesto',
                          );
                          setOpenDialog(true);
                          document.getElementById(
                            `cp-${inputName}`,
                          ).style.display = 'none';
                          formik.setFieldValue(
                            inputName,
                            '',
                          );
                        } else {
                          delegado.sexo = sexVal;
                          delegado.cedula = personCedula;
                          delegado.nombre = personName;
                          delegado.edad = person.num_edad;
                          delegado.enabled = true;
                          delegado.filled = true;

                          const newList = Lodash.union(
                            [delegado],
                            listToDoSearch,
                          );

                          const orderedList = Lodash.orderBy(
                            newList,
                            ['id'],
                            ['asc'],
                          );
                          setAllList(orderedList);
                        }
                      },
                    );
                  }
                } else {
                  delegado.sexo = requireSexOrder
                    ? delegado.sexo
                    : sexFieldAs;
                  delegado.cedula = '';
                  delegado.nombre = '';
                  delegado.edad = '';
                  delegado.filled = false;

                  const newList = Lodash.union(
                    [delegado],
                    listToDoSearch,
                  );
                  const orderedList = Lodash.orderBy(
                    newList,
                    ['id'],
                    ['asc'],
                  );
                  setAllList(orderedList);
                  let sexMessage =
                    'El candidato a ingresar debe de respetar la alternabilidad.';
                  if (!requireSexOrder) {
                    sexMessage =
                      'En esta papeleta solo se permite de ingresar mujeres';
                  }
                  setMsjDialog(sexMessage);
                  setOpenDialog(true);
                  // Se limpian el campo
                  formik.setFieldValue(inputName, '');
                }
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (validateBy !== 'none') {
                setMsjDialog(
                  // eslint-disable-next-line max-len
                  `La persona ingresada NO pertece a la localidad en selección. Ingrese otra cédula`,
                );
                setOpenDialog(true);
                formik.setFieldValue(inputName, '');
              }
            }
          } else {
            setMsjDialog(
              // eslint-disable-next-line max-len
              `La persona ingresada NO posee la edad requerida de JUVENTUD para este puesto`,
            );
            setOpenDialog(true);
            formik.setFieldValue(inputName, '');
          }
          // }
        }
      } else {
        setMsjDialog(
          'El número de cédula ingresado no coincide con ninguna referencia',
        );
        setOpenDialog(true);
        formik.setFieldValue(inputName, '');
      }
      document.getElementById(
        `cp-${inputName}`,
      ).style.display = 'none';
    });
  };

  const validarRenucia = async numCedula => {
    const result = await validateRenuncia(numCedula);
    return result.data.response >= 1;
  };

  const searchPerson = (inputName, lugar) => async () => {
    setCurrentPerson({});
    setInputNameField(inputName);
    setLugarField(lugar);
    const numCedula = document.getElementsByName(
      inputName,
    )[0].value;

    if (numCedula.length > 0 && numCedula.length === 9) {
      const haRenunciado = await validarRenucia(
        numCedula,
        inputName,
      );
      if (haRenunciado) {
        setMsjDialog(
          'De conformidad con el artículo 14 del Estatuto del PLN, no es posible ingresar la candidatura. La persona que desea registrar presentó renuncia al PLN',
        );
        setOpenDialog(true);
      } else {
        document.getElementById(
          `cp-${inputName}`,
        ).style.display = 'block';

        if (findInOtherList) {
          let isInOtherList = false;
          otherList.forEach(list => {
            const existCedula = Lodash.find(list, {
              // eslint-disable-next-line radix
              cedula: parseInt(numCedula),
            });

            if (existCedula) {
              isInOtherList = true;
            }
          });

          if (isInOtherList) {
            const newListData = Lodash.map(listData, d => {
              const newData = {
                id: d.id,
                lugar: d.lugar,
                numPuesto: d.numPuesto,
                fieldName: d.fieldName,
                cedula: '',
                nombre: '',
                sexo: '',
                edad: d.edad,
                enabled: false,
                filled: false,
              };

              return newData;
            });

            // Se limpian todos los campos
            for (let i = 0; i < newListData.length; i++) {
              formik.setFieldValue(
                newListData[i].fieldName,
                '',
              );
            }

            const orderedList = Lodash.orderBy(
              newListData,
              ['id'],
              ['asc'],
            );
            setAllList(orderedList);

            setMsjDialog(
              // eslint-disable-next-line max-len
              'Quien se postule para el cargo de la fiscalía no pueden estar asociado a ningún otro puesto o papeleta',
            );
            setOpenDialog(true);
            document.getElementById(
              `cp-${inputName}`,
            ).style.display = 'none';
            return;
          }
        }

        const existCedula = Lodash.find(listData, {
          // eslint-disable-next-line radix
          cedula: parseInt(numCedula),
        });

        if (existCedula && lugar !== 1) {
          setMsjDialog('Esta cédula ya fué ingresada');
          setOpenDialog(true);
          formik.setFieldValue(inputName, '');
          document.getElementById(
            `cp-${inputName}`,
          ).style.display = 'none';
        } else {
          doSearchByCedula(numCedula, inputName, lugar);
        }
      }
    } else {
      setMsjDialog(
        'Debe digitar un número de cédula válido',
      );
      setOpenDialog(true);
    }
  };

  const removePerson = (inputName, lugar) => () => {
    if (lugar === 1) {
      const newListData = Lodash.map(
        listData,
        (d, index) => {
          const newData = {
            id: d.id,
            lugar: d.lugar,
            numPuesto: d.numPuesto,
            fieldName: d.fieldName,
            cedula: '',
            nombre: '',
            sexo: '',
            enabled: index !== 0,
            filled: false,
          };

          return newData;
        },
      );

      // Se limpian todos los campos
      for (let i = 0; i < newListData.length; i++) {
        // if (i !== 0) {
        formik.setFieldValue(newListData[i].fieldName, '');
        // }
      }

      setAllList(newListData);
    } else {
      // Se obtiene el campo del delegado a editar
      const delegado = Lodash.find(listData, {
        id: lugar,
      });

      delegado.cedula = '';
      delegado.nombre = '';
      delegado.enabled = false;
      delegado.filled = false;

      const newList = Lodash.union([delegado], listData);
      const orderedList = Lodash.orderBy(
        newList,
        ['id'],
        ['asc'],
      );
      formik.setFieldValue(inputName, '');
      setAllList(orderedList);
    }
  };

  const renderList = () => {
    const rows = [];
    const numFields =
      fieldToShow > 0 ? listData.length - fieldToShow : 0;
    const diff = listData.length - numFields;

    for (let n = 0; n < diff; n++) {
      rows.push(listData[n]);
    }

    const allRows = rows.map(row => (
      <TableRow key={`${row.fieldName}-${row.id}`}>
        {!hideSexColumn && (
          <TableCell>
            <strong>{row.lugar}</strong>
          </TableCell>
        )}
        <TableCell>
          <Grid container spacing={0}>
            <Grid item xs={8}>
              <input
                id={row.fieldName}
                type="number"
                disabled={row.enabled}
                className={classes.frmText}
                maxLength={9}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...formik.getFieldProps(row.fieldName)}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton
                type="button"
                className={classes.searchButton}
                aria-label="search"
                style={{ padding: 6 }}
                onClick={searchPerson(
                  row.fieldName,
                  row.id,
                )}
                disabled={row.enabled}
              >
                <SearchIcon />
              </IconButton>
              <CircularProgress
                id={`cp-${row.fieldName}`}
                style={{
                  width: '20px',
                  height: '20px',
                  float: 'right',
                  marginTop: 8,
                  display: 'none',
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <div
            className={
              row.nombre === ''
                ? classes.field
                : classes.fieldFilled
            }
          >
            {row.nombre}
          </div>
        </TableCell>
        {!hideSexColumn && (
          <TableCell>
            <div
              className={
                row.sexo === ''
                  ? classes.fieldMin
                  : classes.fieldMinFilled
              }
            >
              {row.sexo}
            </div>
            {row.edad !== '' &&
              parseInt(row.edad) <= 35 && (
                <Chip
                  label="J"
                  color="primary"
                  className={classes.juventudChip}
                />
              )}

            <IconButton
              type="button"
              className={classes.btnDeleteActive}
              aria-label="remove"
              style={{ padding: 6 }}
              onClick={removePerson(row.fieldName, row.id)}
              disabled={!row.enabled || row.nombre === ''}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
        {hideSexColumn && (
          <TableCell>
            <IconButton
              type="button"
              className={classes.btnDeleteActive}
              aria-label="remove"
              style={{ padding: 6 }}
              onClick={removePerson(row.fieldName, row.id)}
              disabled={!row.enabled || row.nombre === ''}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    ));

    return allRows;
  };

  ListSearch.defaultProps = {
    inPlace: 0,
    findInOtherList: false,
    otherList: [],
    requireSexOrder: true,
    sexFieldAs: '',
    onlyJuventud: false,
    hideSexColumn: false,
    needHojaInfo: true,
    isFiscal: false,
  };

  ListSearch.propTypes = {
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    formik: PropTypes.any.isRequired,
    initListData: PropTypes.arrayOf(PropTypes.shape())
      .isRequired,
    sexOrderM: PropTypes.arrayOf(PropTypes.shape())
      .isRequired,
    sexOrderF: PropTypes.arrayOf(PropTypes.shape())
      .isRequired,
    fieldToShow: PropTypes.number.isRequired,
    inPlace: PropTypes.number,
    setListFunc: PropTypes.func.isRequired,
    findInOtherList: PropTypes.bool,
    otherList: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.shape()),
    ),
    // eslint-disable-next-line react/forbid-prop-types
    validateBy: PropTypes.any.isRequired,
    requireSexOrder: PropTypes.bool,
    sexFieldAs: PropTypes.string,
    onlyJuventud: PropTypes.bool,
    hideSexColumn: PropTypes.bool,
    needHojaInfo: PropTypes.bool,
    isFiscal: PropTypes.bool,
  };

  return (
    <>
      <div>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          className={classes.sectionTitle}
        >
          {title}
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              {!hideSexColumn && (
                <TableCell style={{ width: 135 }}>
                  Lugar
                </TableCell>
              )}
              <TableCell style={{ width: 300 }}>
                Cédula
              </TableCell>
              <TableCell>Nombre</TableCell>
              {!hideSexColumn && (
                <TableCell>Sexo</TableCell>
              )}
              {hideSexColumn && (
                <TableCell>&nbsp;</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{renderList()}</TableBody>
        </Table>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          color="warning"
        >
          ¡Atención!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {msjDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      <ModalHojaInfo
        classes={{
          paper: classes.paper,
        }}
        id="hoja-informacion"
        keepMounted
        open={openHojaInfo}
        onClose={handleCloseHojaInfo}
        currentPerson={currentPerson}
        addPersonToRow={addPersonToRow}
        setCurrentSexChanged={setCurrentSexChanged}
      />
    </>
  );
};

export default withStyles(style)(ListSearch);
