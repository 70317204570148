import axios from 'axios';
import { useQuery } from 'react-query';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const getIGESAvailable = async () => {
  try {
    const url = [`/periods/isIGESAvailable`];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const getRGESMMAvailable = async () => {
  try {
    const url = [`/periods/isRGESMMAvailable`];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const getCRUDAvailable = async () => {
  try {
    const url = [`/periods/isCRUDAvailable`];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const getLoginGESAvailable = async () => {
  try {
    const url = [`/periods/isLoginGESAvailable`];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const getConsultaInscripcionesAvailable = async () => {
  try {
    const url = [
      `/periods/isConsultaInscripcionesAvailable`,
    ];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const getCustomMessage1Available = async () => {
  try {
    const url = [`/periods/isCustomMessage1Available`];

    const { data } = await axios.get(url.join('&'));

    return data;
  } catch (e) {
    return { data: {} };
  }
};

export const useIGESAvailable = () => {
  return useQuery(['iGesAvailable'], () =>
    getIGESAvailable(),
  );
};

export const useRGESMMAvailable = () => {
  return useQuery(['rGesMmAvailable'], () =>
    getRGESMMAvailable(),
  );
};

export const useLoginGESAvailable = () => {
  return useQuery(['loginGESAvailable'], () =>
    getLoginGESAvailable(),
  );
};

export const useConsultaInscripcionesAvailable = () => {
  return useQuery(['consultaInscripcionesAvailable'], () =>
    getConsultaInscripcionesAvailable(),
  );
};

export const useCustomMessage1Available = () => {
  return useQuery(['customMessage1Available'], () =>
    getCustomMessage1Available(),
  );
};

export const useCRUDAvailable = () => {
  return useQuery(['crudAvailable'], () =>
    getCRUDAvailable(),
  );
};
