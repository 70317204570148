/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Lodash from 'lodash';
import { useJwt } from 'react-jwt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { Grid, Button, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Formik, Field } from 'formik';
import {
  PlnInput,
  PlnDropdown,
} from '../../Components/Input';
import PLNDialog from '../../Components/PLNDialog/PLNDialog';
import Loading from '../../Components/Loading/Loading';

import useStyles from './styles';
import {
  getDistritoByTwo,
  useProvincias,
  getCantonsByProvince,
  getProvinces,
} from '../../Core/Api/locationApi';
import fiscalesMesaApi from '../../Core/Api/fiscalesMesaApi';
import Consts from '../../Core/Consts/Consts';
import Copyright from '../Copyright/Copyright';
import Storage from '../../Storage/Storage';

const Transition = React.forwardRef(function Transition(
  tProps,
  ref,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...tProps} />;
});

const EditFiscalMesa = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { data } = useProvincias();

  const validationSchema = Yup.object({});

  const tokenData = Storage.instance;
  const { decodedToken } = useJwt(tokenData.getToken());

  const defaultData = {
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
    celular: '',
    email: '',
    provincia: 9,
    canton: 999,
    distrito: 999,
    jrv: '',
    role: '',
    codProvincia: 0,
    codCanton: 0,
  };

  const [distritos, setDistritos] = useState([
    { itemLabel: '- Seleccione un Distrito -', value: 999 },
  ]);

  const [jrv, setJRV] = useState([
    { itemLabel: '- Seleccione la JRV -', value: 999 },
  ]);

  const [roles, setRoles] = useState([
    { itemLabel: '- Seleccione un Puesto -', value: 999 },
  ]);

  const [initialValues] = useState({
    cedula: '',
    celular: '',
    email: '',
    provincia: 9,
    canton: 999,
    distrito: 999,
    jrv: 999,
    role: 999,
  });

  const [showForm, setShowForm] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingData, setLoading] = useState(true);
  const [open, setOpenDialog] = React.useState(false);

  const [memberId, setMemberId] = useState('');
  const [userLogged, setUser] = useState([]);
  const [memberInfo, setMemberInfo] = useState(defaultData);

  const [open2, setOpenDialog2] = React.useState(false);
  const [msjDialog, setMsjDialog] = useState('');
  const handleClose = () => {
    setOpenDialog2(false);
  };

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);

  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  // eslint-disable-next-line no-unused-vars
  const [sDistrito, setSDistrito] = useState(999);
  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const setProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN-',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO-',
        value: 999,
      },
    ]);
    setJRV([
      {
        itemLabel: '- Seleccione la JRV-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    } else {
      setCantones([
        {
          itemLabel: '- SELECCIONE EL CANTÓN-',
          value: 999,
        },
      ]);
    }
  };

  const setCanton = c => {
    setJRV([
      {
        itemLabel: '- Seleccione la JRV-',
        value: 999,
      },
    ]);
    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;

    if (c !== 999) {
      getDistritoByTwo(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const dlist = distritosList.map(
            ({ cod_distrito, des_nombre_d }) => {
              return {
                itemLabel: des_nombre_d,
                value: cod_distrito,
              };
            },
          );

          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  /**
   * Get Distrits using Prov and Cant
   * @param {*} _provId
   * @param {*} _cantId
   */
  const getDistritos = async (_provId, _cantId) => {
    if (_provId && _cantId) {
      await getDistritoByTwo(_provId, _cantId).then(
        _res => {
          const listDistr = _res.data.response;

          if (listDistr instanceof Array) {
            const dlist = listDistr.map(
              ({ cod_distrito, des_nombre_d }) => {
                return {
                  itemLabel: des_nombre_d,
                  value: cod_distrito,
                };
              },
            );
            dlist.unshift({
              itemLabel: '- SELECCIONE EL DISTRITO -',
              value: 999,
            });

            setDistritos(dlist);
          }
        },
      );
    }
  };

  const getJRVSelected = _jrv => {
    const currentJRV = parseInt(_jrv, 10);

    if (currentJRV > 0 && currentJRV !== 999) {
      // setJRV(currentJRV);
      // console.log(currentJRV);
    }
  };

  const getRoleSelected = _codRole => {
    const currentRole = parseInt(_codRole, 10);

    if (currentRole > 0 && currentRole !== 999) {
      // setRoles(currentJRV);
      // console.log(currentRole);
    }
  };

  const getJRV = async _codDistr => {
    setJRV([
      {
        itemLabel: '- Seleccione la JRV-',
        value: 999,
      },
    ]);
    const currentDistr = parseInt(_codDistr, 10);
    if (currentDistr > 0 && currentDistr !== 999) {
      await fiscalesMesaApi
        .getJuntaReceptora(
          sProvincia.value,
          sCanton.value,
          currentDistr,
        )
        .then(_res => {
          const listJRV = _res.data.response;
          if (listJRV instanceof Array) {
            const jrvlist = listJRV.map(
              ({ num_jvirt, index }) => {
                return {
                  itemLabel: `Nº ${num_jvirt}`,
                  value: num_jvirt,
                };
              },
            );
            jrvlist.unshift({
              itemLabel: '- Seleccione la JRV -',
              value: 999,
            });

            setJRV(jrvlist);
          }
        });
    }
  };

  const buildRolesDropdown = () => {
    if (Consts.puestosFiscalesMesa.length > 0) {
      const rolesList = Consts.puestosFiscalesMesa.map(
        ({ codigo, descripcion }) => {
          return {
            itemLabel: descripcion,
            value: codigo,
          };
        },
      );

      rolesList.unshift({
        itemLabel: '- Seleccione un Puesto -',
        value: 999,
      });

      setRoles(rolesList);
    }
  };

  /**
   * Save Member
   * @param {*} values
   */
  const saveMember = values => {
    setIsSaving(true);

    const memberData = {
      cod_provincia: parseInt(values.provincia, 10),
      cod_canton: parseInt(values.canton, 10),
      cod_distrito: parseInt(values.distrito, 10),
      cod_mesa: parseInt(values.jrv, 10),
      num_cedula: parseInt(values.cedula, 10),
      cod_puesto: values.role,
      num_linea: parseInt(id, 10),
      des_usuario: parseInt(userLogged.num_cedula, 10),
      fec_digitado: null,
      cod_periodo: null,
    };

    const userData = {
      codUsuario: userLogged.num_cedula,
    };

    const phoneData = {
      tel_celu: values.celular,
    };

    const emailData = {
      des_email: values.email,
    };

    const dataSet = {
      miembro: memberData,
      usuario: userData,
      telefonos: phoneData,
      emails: emailData,
      isUpdate: 1,
    };

    const jrvVal = parseInt(values.jrv, 10);
    const puesto = values.role;
    const cedUser = parseInt(userLogged.num_cedula, 10);

    fiscalesMesaApi
      .submitFiscalMesa(dataSet)
      .then(_response => {
        if (
          _response.status === 200 &&
          _response.data.response === 1
        ) {
          setIsSaving(false);
          setOpenDialog(true);
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'No se pudo completar la operacion, vuelva a intentar',
          );
        }
      });
  };

  const loadMemberDetails = async _numLine => {
    try {
      let dataSet = {};
      if (_numLine) {
        const info = await fiscalesMesaApi.getFiscalInfoByLinea(
          _numLine,
        );
        const data = info.data.response;

        if (data instanceof Array && info.status === 200) {
          dataSet = {
            primerApellido: data[0].des_apell1,
            segundoApellido: data[0].des_apell2,
            nombre: data[0].des_nombre,
            celular: data[0].tel_celu || '',
            email: data[0].des_email || '',
            provincia: data[0].cod_provincia,
            canton: data[0].cod_canton,
            distrito: data[0].cod_distrito,
            jrv: data[0].cod_mesa,
            role: data[0].cod_puesto,
            codProvincia: data[0].cod_provincia,
            codCanton: data[0].cod_canton,
            cedula: data[0].num_cedula,
          };
          const listProvincias = await getProvinces();
          const provincias = Lodash.get(
            listProvincias,
            ['frmOptions'],
            [
              {
                itemLabel: '- SELECCIONE LA PROVINCIA -',
                value: 9,
              },
            ],
          );

          setSProvincia(
            Lodash.find(provincias, {
              value: data[0].cod_provincia,
            }),
          );

          getCantonsByProvince(data[0].cod_provincia).then(
            result => {
              if (result.status === 200) {
                const cantonsList = Lodash.get(result, [
                  'data',
                  'response',
                ]);

                const clist = cantonsList.map(
                  ({ cod_canton, des_nombre_c }) => {
                    return {
                      itemLabel: des_nombre_c,
                      value: cod_canton,
                    };
                  },
                );
                clist.unshift({
                  itemLabel: '- SELECCIONE EL CANTÓN-',
                  value: 999,
                });

                setCantones(clist);

                setSCanton(
                  Lodash.find(clist, {
                    value: data[0].cod_canton,
                  }),
                );
              }
            },
          );

          await fiscalesMesaApi
            .getJuntaReceptora(
              data[0].cod_provincia,
              data[0].cod_canton,
              data[0].cod_distrito,
            )
            .then(_res => {
              const listJRV = _res.data.response;
              if (listJRV instanceof Array) {
                const jrvlist = listJRV.map(
                  ({ num_jvirt, index }) => {
                    return {
                      itemLabel: `Nº ${num_jvirt}`,
                      value: num_jvirt,
                    };
                  },
                );
                jrvlist.unshift({
                  itemLabel: '- Seleccione la JRV -',
                  value: 999,
                });

                setJRV(jrvlist);
              }
            });
        } else {
          dataSet = defaultData;
        }
      } else {
        dataSet = defaultData;
      }

      setMemberInfo(dataSet);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  useEffect(() => {
    async function fetchAPI() {
      const currentNumLinea = parseInt(id, 10);

      // eslint-disable-next-line no-restricted-globals
      if (currentNumLinea === 0 || isNaN(currentNumLinea)) {
        history.push('/fiscales-mesa');
      }

      setLoading(true);

      await loadMemberDetails(currentNumLinea);
    }

    fetchAPI();
  }, []);

  useEffect(() => {
    getDistritos(
      memberInfo.codProvincia,
      memberInfo.codCanton,
    );
    // getJRV(memberInfo.distrito);
    buildRolesDropdown();
    setUser(decodedToken);
  }, [memberInfo]);

  /**
   * Form validations
   * @param {*} values
   */
  const formikValidate = values => {
    const errors = {};
    // eslint-disable-next-line no-shadow
    const { celular, email, distrito, jrv, role } = values;

    if (!celular) {
      errors.celular = 'Requerido';
    } else if (!/^[0-9]{8}$/.test(celular)) {
      errors.celular = 'Ejemplo de formato: xxxxxxxx.';
    }

    if (!email) {
      errors.email = 'Requerido';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        email,
      )
    ) {
      errors.email = 'Email inválido';
    }

    if (distrito === 999) {
      errors.distrito = 'Seleccione un distrito';
    }

    if (jrv === 999) {
      errors.jrv = 'Seleccione la Junta Receptora';
    }

    if (role === 999) {
      errors.role = 'Seleccione un puesto';
    }

    return errors;
  };

  const closeDialogHandler = () => {
    history.push('/fiscales-mesa');
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Editar Fiscal de Mesa
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnBack}
                onClick={() => {
                  history.push('/fiscales-mesa');
                }}
              >
                <ArrowBackIcon /> Regresar
              </Button>
            </Typography>
            {!isLoadingData ? (
              <Formik
                validate={formikValidate}
                initialValues={memberInfo}
                validationSchema={validationSchema}
                onSubmit={saveMember}
              >
                {formik => {
                  return (
                    <form
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <h2>
                            # Cédula: {memberInfo.cedula}
                          </h2>
                        </Grid>
                      </Grid>
                      <Divider style={{ marginTop: 10 }} />
                      {showForm ? (
                        <>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 20 }}
                          >
                            <Grid item xs={12} sm={3}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                1er. Apellido
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {memberInfo.primerApellido}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                2do. Apellido
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {memberInfo.segundoApellido}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Nombre
                              </h4>
                              <div
                                className={
                                  classes.fieldFilled
                                }
                              >
                                {memberInfo.nombre}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                              <h4># Celular:</h4>
                              <Field
                                mask="99999999"
                                name="celular"
                                data={formik.values.celular}
                                component={PlnInput}
                              />
                              {formik.errors.celular &&
                              formik.touched.celular ? (
                                <div
                                  className={
                                    classes.formError
                                  }
                                >
                                  <Alert severity="error">
                                    {formik.errors.celular}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <h4>Correo Principal:</h4>
                              <Field
                                type="email"
                                name="email"
                                data={formik.values.email}
                                component={PlnInput}
                              />
                              {formik.errors.email &&
                              formik.touched.email ? (
                                <div
                                  className={
                                    classes.formError
                                  }
                                >
                                  <Alert severity="error">
                                    {formik.errors.email}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Provincia
                              </h4>
                              <div>
                                <Field
                                  name="provincia"
                                  data={provincias}
                                  component={PlnDropdown}
                                  onChange={provincia => {
                                    setProvincia(provincia);
                                    formik.setFieldValue(
                                      'canton',
                                      999,
                                    );
                                    formik.setFieldValue(
                                      'distrito',
                                      999,
                                    );
                                  }}
                                />
                                {formik.errors.distrito &&
                                formik.touched.distrito ? (
                                  <div
                                    className={
                                      classes.formError
                                    }
                                  >
                                    <Alert severity="error">
                                      {
                                        formik.errors
                                          .distrito
                                      }
                                    </Alert>
                                  </div>
                                ) : null}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Cantón
                              </h4>
                              <div>
                                <Field
                                  name="canton"
                                  data={cantones}
                                  component={PlnDropdown}
                                  onChange={canton => {
                                    setCanton(canton);
                                    formik.setFieldValue(
                                      'distrito',
                                      999,
                                    );
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                              >
                                Distrito
                              </h4>
                              <div>
                                <Field
                                  name="distrito"
                                  data={distritos}
                                  component={PlnDropdown}
                                  onChange={dist => {
                                    getJRV(dist);
                                  }}
                                />
                                {formik.errors.distrito &&
                                formik.touched.distrito ? (
                                  <div
                                    className={
                                      classes.formError
                                    }
                                  >
                                    <Alert severity="error">
                                      {
                                        formik.errors
                                          .distrito
                                      }
                                    </Alert>
                                  </div>
                                ) : null}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                                style={{ marginTop: 0 }}
                              >
                                JRV
                              </h4>
                              <Field
                                name="jrv"
                                data={jrv}
                                component={PlnDropdown}
                                onChange={jrvItem =>
                                  getJRVSelected(jrvItem)
                                }
                              />
                              {formik.errors.jrv &&
                              formik.touched.jrv ? (
                                <div
                                  className={
                                    classes.formError
                                  }
                                >
                                  <Alert severity="error">
                                    {formik.errors.jrv}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <h4
                                className={
                                  classes.fieldTitle
                                }
                                style={{ marginTop: 0 }}
                              >
                                Puesto
                              </h4>
                              <Field
                                name="role"
                                data={roles}
                                component={PlnDropdown}
                                onChange={role =>
                                  getRoleSelected(role)
                                }
                              />
                              {formik.errors.role &&
                              formik.touched.role ? (
                                <div
                                  className={
                                    classes.formError
                                  }
                                >
                                  <Alert severity="error">
                                    {formik.errors.role}
                                  </Alert>
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={3}
                            style={{ marginTop: 40 }}
                          >
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  formik.isSubmitting ||
                                  isSaving
                                    ? classes.btnSubmitting
                                    : classes.btnActions
                                }
                                disabled={isSaving}
                              >
                                {isSaving ? (
                                  <CircularProgress
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                    }}
                                  />
                                ) : (
                                  <>
                                    Actualizar
                                    <SaveIcon
                                      className={
                                        classes.frmIconButton
                                      }
                                    />
                                  </>
                                )}
                              </Button>
                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={
                                  classes.btnActionCancel
                                }
                                onClick={() => {
                                  history.push(
                                    '/fiscales-mesa',
                                  );
                                }}
                              >
                                Cancelar
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )}
                    </form>
                  );
                }}
              </Formik>
            ) : (
              <Loading label="Cargando Informacion" />
            )}
            <PLNDialog
              open={open}
              onClose={closeDialogHandler}
              title="Mensaje"
              bodymessage="Información actualizada correctamente."
              buttons={[
                {
                  actionName: 'close',
                  color: 'simple',
                  title: 'Entendido',
                  clickHander: closeDialogHandler,
                },
              ]}
            />
            <Dialog
              open={open2}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle
                id="alert-confirm-messages"
                color="warning"
              >
                Mensaje
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {msjDialog}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                >
                  Entendido
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default EditFiscalMesa;
